<template>
    <!-- <div>嘿嘿,当前模块正在开发中</div> -->
    <div class="container">
        <!-- 图片 -->
        <div class="img_box">
            <img class="img_box_img" src="@/assets/developping_images/developping.png" alt="正在开发中...">
        </div>
        <!-- 文字说明 -->
        <div class="desc_box">
            <span>该模块建设中,敬请期待...</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.container {
    min-height: 600px;
    .img_box {
        // width: 1200px;
        // min-height: 600px;
        // background-color: pink;
        text-align: center;
        height: 100%;
        padding-top: 10%;
        .img_box_img {
            width: 30%;
            // background-color: red;
        }
    }
    .desc_box {
        text-align: center;
        color: #1482f0;
        font-size: 20px;
        padding: 20px 0;
    }
}
</style>


